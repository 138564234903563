import { createWebHistory, createRouter } from "vue-router";

import LandingPage from "@/components/LandingPage.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

const routes = [
	{
		path: "/",
		name: "Central Wallpapers",
		component: LandingPage,
	},
	{
		path: "/privacypolicy",
		name: "Privacy Policy",
		component: PrivacyPolicy,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
