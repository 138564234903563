import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import * as firebase from "firebase/app";

createApp(App).use(router).mount("#app");

firebase.initializeApp({
	apiKey: "AIzaSyB_d7u6HUh0rMhxuZA5ElHYJ5I4QSTiqGg",
	authDomain: "studio1-8fddb.firebaseapp.com",
	projectId: "studio1-8fddb",
	storageBucket: "studio1-8fddb.appspot.com",
	messagingSenderId: "584800017436",
	appId: "1:584800017436:web:c641dcdc1aac726f1093d9",
});
