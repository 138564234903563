<template>
  <div class="p-8">
    <div class="bg-white p-4 rounded-lg shadow-xl py-8 mt-12">
      <h4
        class="
          text-4xl
          font-bold
          text-rose-800
          tracking-widest
          uppercase
          text-center
        "
      >
        Welcome to Central Wallpapers
      </h4>
      <router-link to="/privacypolicy"
        ><p class="text-center text-gray-600 text-sm mt-2">
          <span class="bg-rose-300">Our Privacy Policy</span>
        </p></router-link
      >
      <div class="space-y-12 px-2 xl:px-16 mt-12">
        <div class="mt-4 flex">
          <div>
            <div class="flex items-center h-16 border-l-4 border-blue-600">
              <span class="text-4xl text-rose-600 px-4">Q.</span>
            </div>
            <div class="flex items-center h-16 border-l-4 border-gray-400">
              <span class="text-4xl text-gray-400 px-4">A.</span>
            </div>
          </div>
          <div>
            <div class="flex items-center h-16">
              <span class="text-lg text-rose-600 font-bold">Who are we?</span>
            </div>
            <div class="flex items-center py-2">
              <span class="text-gray-500">
                We are a developer team from Central Wallpapers working to create a special application for your favorite wallpapers. We provide this for free for you guys</span
              >
            </div>
          </div>
        </div>

        <div class="mt-4 flex">
          <div>
            <div class="flex items-center h-16 border-l-4 border-blue-600">
              <span class="text-4xl text-rose-600 px-4">Q.</span>
            </div>
            <div class="flex items-center h-16 border-l-4 border-gray-400">
              <span class="text-4xl text-gray-400 px-4">A.</span>
            </div>
          </div>
          <div>
            <div class="flex items-center h-16">
              <span class="text-lg text-rose-600 font-bold"
                >Actually, why do we make lots of wallpapers?</span
              >
            </div>
            <div class="flex items-center py-2">
              <span class="text-gray-500">
                We make lots of wallappers to make it easier for you to easily change the wallpaper on your cellphone without you having to search in a browser which makes you bored because it requires a lot of effort</span
              >
            </div>
          </div>
        </div>

        <div class="mt-4 flex">
          <div>
            <div class="flex items-center h-16 border-l-4 border-blue-600">
              <span class="text-4xl text-rose-600 px-4">Q.</span>
            </div>
            <div class="flex items-center h-16 border-l-4 border-gray-400">
              <span class="text-4xl text-gray-400 px-4">A.</span>
            </div>
          </div>
          <div>
            <div class="flex items-center h-16">
              <span class="text-lg text-rose-600 font-bold"
                >What are we making this app for?</span
              >
            </div>
            <div class="flex items-center py-2">
              <span class="text-gray-500">
                  for those of you who like to change the wallpaper on your cellphone.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <img src="/src/assets/logo.png" alt="" /> -->
    <footer class="bg-zinc-100 text-center lg:text-left">
     <div
        class="text-gray-700 text-center p-4 text-rose-600"
      >
        {{ currentDate() }} Copyright: Central Wallpapers
      </div>
    </footer>
  </div>
</template>
<script>
import moment from "moment";

export default {
  methods: {
    currentDate() {
      return moment().format("YYYY");
    },
  },
};
</script>
