<template>
  <div class="p-8">
    <div class="bg-white p-4 rounded-lg shadow-xl py-8 mt-12">
      <h4
        class="
          text-4xl
          font-bold
          text-rose-800
          tracking-widest
          uppercase
          text-center
        "
      >
        Privacy Policy
      </h4>
      <router-link to="/"
        ><p class="text-center text-gray-600 text-sm mt-2">
          <span class="bg-rose-300">Back to Home</span>
        </p></router-link
      >
      <div
        class="
          grid grid-cols-1
          md:grid-cols-1
          gap-4
          xl:gap-12
          px-2
          mt-4
          mr-30
          md:mr-10
          -ml-30
        "
      >
        <div class="flex space-x-10 mt-8 ml-10">
          <div>
            <h4 class="text-xl font-bold text-gray-700">Privacy Policy</h4>
            <p class="text-lg text-gray-700">Last updated: December 10, 2022</p>
            <p class="text-gray-600 my-2">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p class="text-gray-600 my-2">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the
              <a
                href="https://www.termsfeed.com/privacy-policy-generator/"
                rel="nofollow"
                target="_blank"
                >Privacy Policy Generator</a
              >.
            </p>
            <h4 class="text-xl font-bold text-gray-700 mt-10">
              Interpretation and Definitions
            </h4>
            <h2 class="text-lg text-gray-700 mt-5">
              <strong>Interpretation</strong>
            </h2>
            <p class="text-gray-600 my-2">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h2 class="text-lg text-gray-700"><strong>Definitions</strong></h2>
            <p class="text-gray-600 my-2">
              For the purposes of this Privacy Policy:
            </p>
            <ul class="list-disc">
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where
                  &quot;control&quot; means ownership of 50% or more of the
                  shares, equity interest or other securities entitled to vote
                  for election of directors or other managing authority.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device, named Central Wallpapers
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Company (referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                  Agreement) refers to Central Wallpapers.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Country refers to: California, United States
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Personal Data is any information that relates to an identified
                  or identifiable individual.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Service refers to the Application.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Service Provider means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  Usage Data refers to data collected automatically, either
                  generated by the use of the Service or from the Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>
              </li>
            </ul>
            <h4 class="text-xl font-bold text-gray-700 mt-10">
              Collecting and Using Your Personal Data
            </h4>
            <h2 class="text-lg text-gray-700 mt-5">
              <strong>Types of Data Collected</strong>
            </h2>
            <h3 class="text-lg text-gray-700 mt-5">
              <strong>Personal Data</strong>
            </h3>
            <p class="text-gray-600 my-2">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to Usage Data
            </p>
            <h3 class="text-lg text-gray-700 mt-5">
              <strong>Usage Data</strong>
            </h3>
            <p class="text-gray-600 my-2">
              Usage Data is collected automatically when using the Service.
            </p>
            <p class="text-gray-600 my-2">
              Usage Data may include information such as Your Device&apos;s
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that You visit, the time and
              date of Your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p class="text-gray-600 my-2">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p class="text-gray-600 my-2">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h2 class="text-lg text-gray-700 mt-5">
              Use of Your Personal Data
            </h2>
            <p class="text-gray-600 my-2">
              The Company may use Personal Data for the following purposes:
            </p>
            <ul class="list-disc">
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  To provide and maintain our Service, including to monitor the
                  usage of our Service.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  To manage Your Account: to manage Your registration as a user
                  of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are
                  available to You as a registered user.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  For the performance of a contract: the development, compliance
                  and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract
                  with Us through the Service.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  To contact You: To contact You by email, telephone calls, SMS,
                  or other equivalent forms of electronic communication, such as
                  a mobile application&apos;s push notifications regarding
                  updates or informative communications related to the
                  functionalities, products or contracted services, including
                  the security updates, when necessary or reasonable for their
                  implementation.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  To provide You with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless You have opted not to
                  receive such information.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  To manage Your requests: To attend and manage Your requests to
                  Us.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  For business transfers: We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of Our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </p>
              </li>
              <li class="ml-10">
                <p class="text-gray-600 my-2">
                  For other purposes: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and
                  to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </p>
              </li>
            </ul>
            <p class="text-gray-600 my-2">
              We may share Your personal information in the following
              situations:
            </p>
            <ul class="list-disc">
              <li class="ml-10">
                With Service Providers: We may share Your personal information
                with Service Providers to monitor and analyze the use of our
                Service, to contact You.
              </li>
              <li class="ml-10">
                For business transfers: We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of Our business to another company.
              </li>
              <li class="ml-10">
                With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </li>
              <li class="ml-10">
                With business partners: We may share Your information with Our
                business partners to offer You certain products, services or
                promotions.
              </li>
              <li class="ml-10">
                With other users: when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside.
              </li>
              <li class="ml-10">
                With Your consent: We may disclose Your personal information for
                any other purpose with Your consent.
              </li>
            </ul>
            <h2 class="text-lg text-gray-700 mt-5">
              <strong>Retention of Your Personal Data</strong>
            </h2>
            <p class="text-gray-600 my-2">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p class="text-gray-600 my-2">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h2 class="text-lg text-gray-700 mt-5">
              <strong> Transfer of Your Personal Data</strong>
            </h2>
            <p class="text-gray-600 my-2">
              Your information, including Personal Data, is processed at the
              Company&apos;s operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to &mdash; and maintained on
              &mdash; computers located outside of Your state, province, country
              or other governmental jurisdiction where the data protection laws
              may differ than those from Your jurisdiction.
            </p>
            <p class="text-gray-600 my-2">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p class="text-gray-600 my-2">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h2 class="text-lg text-gray-700 mt-5">
              <strong> Disclosure of Your Personal Data</strong>
            </h2>
            <h3 class="text-lg text-gray-700 mt-5">Business Transactions</h3>
            <p class="text-gray-600 my-2">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h3 class="text-lg text-gray-700 mt-5">Law enforcement</h3>
            <p class="text-gray-600 my-2">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h3 class="text-lg text-gray-700 mt-5">Other legal requirements</h3>
            <p class="text-gray-600 my-2">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul class="list-disc">
              <li class="ml-10">Comply with a legal obligation</li>
              <li class="ml-10">
                Protect and defend the rights or property of the Company
              </li>
              <li class="ml-10">
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li class="ml-10">
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li class="ml-10">Protect against legal liability</li>
            </ul>
            <h2>Security of Your Personal Data</h2>
            <p class="text-gray-600 my-2">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <h4 class="text-xl font-bold text-gray-700">
              Children&apos;s Privacy
            </h4>
            <p class="text-gray-600 my-2">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>
            <p class="text-gray-600 my-2">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent&apos;s consent before We collect and use
              that information.
            </p>
            <h4 class="text-xl font-bold text-gray-700">
              Links to Other Websites
            </h4>
            <p class="text-gray-600 my-2">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party&apos;s site. We strongly advise You
              to review the Privacy Policy of every site You visit.
            </p>
            <p class="text-gray-600 my-2">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h4 class="text-xl font-bold text-gray-700">
              Changes to this Privacy Policy
            </h4>
            <p class="text-gray-600 my-2">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p class="text-gray-600 my-2">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              &quot;Last updated&quot; date at the top of this Privacy Policy.
            </p>
            <p class="text-gray-600 my-2">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h4 class="text-xl font-bold text-gray-700">Contact Us</h4>
            <p class="text-gray-600 my-2">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul>
              <li><strong>By email: centralwallpapers@gmail.com</strong></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <footer class="bg-zinc-100 text-center lg:text-left">
      <div
        class="text-gray-700 text-center p-4 text-rose-600"
      >
        {{ currentDate() }} Copyright: Central Wallpapers
      </div>
    </footer>
  </div>
</template> 
<script>
import moment from "moment";

export default {
  methods: {
    currentDate() {
      return moment().format("YYYY");
    },
  },
};
</script>
